import React, { Component } from "react";
import Logo from "./logo-center.png";
import emailIcon from "./email.png";

class Maintenance extends Component {
  state = {
    hireLink: "https://wwww.freelancer.com/u/webozza",
    emailAddress: "mailto:webozza@gmail.com",
  };
  render() {
    return (
      <div id="maintenance">
        <img className="logo" src={Logo} alt="logo" />
        <a href={this.state.hireLink} title="View Profile">
          Wanna know more?
        </a>
        <a
          class="email-link"
          href={this.state.emailAddress}
          title="Email Webozza"
        >
          <img class="w-icon" src={emailIcon}></img>
        </a>
      </div>
    );
  }
}

export default Maintenance;
